<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
    <vx-card v-bind:title="$t('Price Management')">

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full ">
          <label class="text-sm opacity-75">{{ $t("Individual Doctor Markup Fee") }}</label>
          <vs-input v-model="PriceManagmentModel.IndividualDoctorMarkupFee" class=" w-full"
            name="IndividualDoctorMarkupFee" :data-vv-as="$t('Individual Doctor Markup Fee ')" v-validate="'required'" />
          <span class="text-danger text-sm" v-show="errors.has('IndividualDoctorMarkupFee')">{{
            errors.first("IndividualDoctorMarkupFee")
          }}</span>
        </div>

        <div class="vx-col md:w-1/4w-full  ">
          <vs-checkbox color="success" class="mt-8" v-model="PriceManagmentModel.IsPercentageIndividualDoctorMarkupFee">Is
            Percentage</vs-checkbox>
        </div>

        <div class="vx-col md:w-1/4 w-full ">
          <label class="text-sm opacity-75">{{ $t("Doctor Specialty Markup Fee") }}</label>
          <vs-input v-model="PriceManagmentModel.DoctorSpecialtyMarkupFee" class=" w-full" name="DoctorSpecialtyMarkupFee"
            :data-vv-as="$t('Doctor Specialty Markup Fee ')" v-validate="'required'" />
          <span class="text-danger text-sm" v-show="errors.has('DoctorSpecialtyMarkupFee')">{{
            errors.first("DoctorSpecialtyMarkupFee")
          }}</span>
        </div>

        <div class="vx-col md:w-1/4w-full  ">
          <vs-checkbox color="success" class="mt-8" v-model="PriceManagmentModel.IsPercentageDoctorSpecialtyMarkupFee">Is
            Percentage</vs-checkbox>
        </div>


      </div>
      <div class="vx-row mt-12">
        <div class="vx-col md:w-1/4 w-full ">
          <label class="text-sm opacity-75">{{ $t("Individual Doctor Markup Fee") }}</label>
          <vs-input v-model="PriceManagmentModel.PatientsMarkupFee" class=" w-full" name="PatientsMarkupFee"
            :data-vv-as="$t('Patients Markup Fee ')" v-validate="'required'" />
          <span class="text-danger text-sm" v-show="errors.has('PatientsMarkupFee')">{{
            errors.first("PatientsMarkupFee")
          }}</span>
        </div>

        <div class="vx-col md:w-1/4w-full  ">
          <vs-checkbox color="success" class="mt-8" v-model="PriceManagmentModel.IsPercentagePatientsMarkupFee">Is
            Percentage</vs-checkbox>
        </div>

        <div class="vx-col md:w-1/4 w-full ">
          <label class="text-sm opacity-75">{{ $t("Online Appointment Markup Fee") }}</label>
          <vs-input v-model="PriceManagmentModel.OnlineAppointmentMarkupFee" class=" w-full"
            name="OnlineAppointmentMarkupFee" :data-vv-as="$t('Online Appointment Markup Fee')" v-validate="'required'" />
          <span class="text-danger text-sm" v-show="errors.has('OnlineAppointmentMarkupFee')">{{
            errors.first("OnlineAppointmentMarkupFee")
          }}</span>
        </div>

        <div class="vx-col md:w-1/4w-full  ">
          <vs-checkbox color="success" class="mt-8"
            v-model="PriceManagmentModel.IsPercentageOnlineAppointmentMarkupFee">Is Percentage</vs-checkbox>
        </div>


      </div>
      <!-- <div class="vx-row mt-12">
        <div class="vx-col md:w-1/4 w-full ">
          <label class="text-sm opacity-75">{{ $t("Surgeries Markup Fee") }}</label>
          <vs-input v-model="PriceManagmentModel.SurgeriesMarkupFee" class=" w-full" name="SurgeriesMarkupFee"
            :data-vv-as="$t('Surgeries Markup Fee')" v-validate="'required'" />
          <span class="text-danger text-sm" v-show="errors.has('Full Name')">{{
            errors.first("IndividualDoctorMarkupFee")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4w-full  ">
          <vs-checkbox color="success" class="mt-8" v-model="PriceManagmentModel.IsPercentageSurgeriesMarkupFee">Is
            Percentage</vs-checkbox>
        </div>
      </div> -->
    </vx-card>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="mr-6" @click="submitData">{{ $t("Save") }}</vs-button>
          <vs-button type="border" color="danger" :to="{ name: 'home' }">{{
            $t("Cancel")
          }}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { domain } from "@/gloabelConstant.js";


import modulePriceManagement from "@/store/settings/PriceManagement/modulePriceManagement.js";

export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      search: {},
      PriceManagmentModel: {},

      baseURL: domain,
      update: false
    };
  },
  computed: {

  },
  methods: {

    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {

          this.$store
            .dispatch("PriceManagementList/UpdatePriceManagement", this.PriceManagmentModel)
            .then(() => {

              this.$router.push({ name: "home" });
            });


        }
      });
    }
  },
  created() {
    if (!modulePriceManagement.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("PriceManagementList", modulePriceManagement);
      modulePriceManagement.isRegistered = true;
    }

    this.$store.dispatch("PriceManagementList/GetAllPriceManagement").then(() => {
      this.PriceManagmentModel = this.$store.state.PriceManagementList.PriceManagement[0];

    })
      .catch(() => {
        this.$vs.loading.close();
      });
  }
};
</script>
