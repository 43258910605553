

import state from './modulePriceManagementState.js'
import mutations from './modulePriceManagementMutations.js'
import actions from './modulePriceManagementActions.js'
import getters from './modulePriceManagementGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
