/*=========================================================================================

==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddCurrency({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/PriceManagement/AddPriceManagement", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  GetAllPriceManagement({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/PriceManagement/GetAllPriceManagement")
        .then((response) => {
          commit('SET_PriceManagement', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  UpdatePriceManagement({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/PriceManagement/UpdatePriceManagement", item)
        .then((response) => {
          commit('UPDATE_PriceManagement', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetPriceManagement({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/PriceManagement/GetPriceManagement", itemid)
        .then((response) => {
          commit('UPDATE_PriceManagement', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeletePriceManagement({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/PriceManagement/DeletePriceManagement?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
