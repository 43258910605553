/*=========================================================================================

==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.PriceManagement.unshift(item)
  },
  SET_PriceManagement(state, PriceManagement) {
    state.PriceManagement = PriceManagement
  },
  UPDATE_PriceManagement(state, PriceManagement) {
    const PriceManagementIndex = state.PriceManagement.findIndex((p) => p.ID == PriceManagement.ID)
    Object.assign(state.PriceManagement[PriceManagementIndex], PriceManagement)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.PriceManagement.findIndex((p) => p.ID == itemId)
    state.PriceManagement.splice(ItemIndex, 1)
},
}
